import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';
import 'select2/src/scss/core';
import "select2";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.createSelect2Events();
  }

  createSelect2Events() {
    const { element } = this;
    const options = {
      theme: "bootstrap4",
      width: "100%",
      tags: true,
      placeholder: $(element).attr("placeholder"),
      tokenSeparators: [","],
      createTag: ({ term }) => ({ id: term, text: `${term}` })
    };

    $(element)
      .select2(options)
      .on("select2:select", function () {
        const event = new Event("change", { bubbles: true });
        element.dispatchEvent(event);
      })
      .on("select2:unselect", function () {
        // element.value = undefined;
        const event = new Event("change", { bubbles: true });
        element.dispatchEvent(event);
      })
      .on("select2:opening", () => {
        $("#sendInvitation").removeAttr("tabindex", "-1");
      }).on("select2:close", () => {
        $("#sendInvitation").attr("tabindex", "-1");
      })
  }
}
