import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input']

  connect() {
  }

  click(event) {
    this.inputTarget.select()
    this.inputTarget.setSelectionRange(0, 99999)
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }
}
