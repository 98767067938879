import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  sendForm(event) {
    this.element.submit()
  }
}
