import { Controller } from "stimulus"

export default class extends Controller {

  formSubmit(event) {
    const { target: form } = event
    const timeout = form.dataset.timeout || 1000
    const submitButton = form.querySelector('button[type="submit"]');

    submitButton.classList.toggle("disabled")
    setTimeout(() => {
      submitButton.classList.toggle("disabled")
    }, timeout)
  }

  buttonClick(event) {
    const { target } = event
    const timeout = target.dataset.timeout || 1000

    target.classList.toggle("disabled")
    setTimeout(() => {
      target.classList.toggle("disabled")
    }, timeout)
  }
}
